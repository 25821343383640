var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      staticClass: "pa-0 ma-0",
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "toolbar" } },
        [
          _c("v-toolbar-title", { staticClass: "ml-2 title" }, [
            _vm._v(" Batch Billing "),
          ]),
          _c("v-divider", {
            staticClass: "mx-4",
            attrs: { inset: "", vertical: "" },
          }),
          _c(
            "v-row",
            { staticClass: "mt-4", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "pr-0", attrs: { md: "4", lg: "5", xl: "5" } },
                [
                  _c("v-text-field", {
                    staticClass: "ma-0 pa-2 pt-3 pb-4 pr-0",
                    staticStyle: {
                      "border-bottom-right-radius": "0px",
                      "border-top-right-radius": "0px",
                    },
                    attrs: {
                      flat: "",
                      solo: "",
                      placeholder: _vm.search.key
                        ? "Search By " + _vm.search.key.description
                        : "Select Search Field",
                      outlined: "",
                      dense: "",
                      height: "10px",
                      "background-color": "input",
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchBatches()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-inner",
                        fn: function () {
                          return [
                            _c("v-autocomplete", {
                              staticClass: "mx-1 pb-2 mt-0 pt-8",
                              attrs: {
                                placeholder: "Search By",
                                "append-icon": ":",
                                items: _vm.searchParams,
                                flat: "",
                                solo: "",
                                "item-text": "description",
                                "return-object": "",
                                dense: "",
                              },
                              model: {
                                value: _vm.search.key,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search, "key", $$v)
                                },
                                expression: "search.key",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                color: "primary",
                                                loading: _vm.searching,
                                                disabled: _vm.buttonDisabled,
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.searched === false
                                                    ? _vm.searchBatches()
                                                    : _vm.clearSearch()
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "white--text" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.searched
                                                        ? "mdi-close"
                                                        : "mdi-magnify"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.searched
                                        ? "Clear Search"
                                        : "Search Batches"
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.search.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.search, "value", $$v)
                      },
                      expression: "search.value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: {
                    "open-on-hover": "",
                    transition: "slide-y-transition",
                    bottom: "",
                    "offset-y": "",
                    "nudge-left": 75,
                    "close-on-content-click": false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticStyle: {
                                    "border-bottom-left-radius": "0px",
                                    "border-top-left-radius": "0px",
                                    border: "1px solid #9e9e9e",
                                    "border-left-style": "hidden",
                                    "margin-bottom": "30px",
                                  },
                                  attrs: { depressed: "", height: "40" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { color: "main" } }, [
                                _vm._v("mdi-filter-outline"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto",
                      attrs: { "max-width": "300", tile: "" },
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "text-subtitle-2" },
                            [_vm._v(" Filter by Status ")]
                          ),
                          _c(
                            "v-list-item-group",
                            { attrs: { color: "primary" } },
                            _vm._l(_vm.statusList, function (state, i) {
                              return _c(
                                "v-list-item",
                                { key: i },
                                [
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("v-checkbox", {
                                        key: i,
                                        attrs: { dense: "", value: state },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    { staticClass: "ml-2" },
                                                    [_vm._v(_vm._s(state))]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.states,
                                          callback: function ($$v) {
                                            _vm.states = $$v
                                          },
                                          expression: "states",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text mb-7",
                  attrs: {
                    height: "40",
                    color: "#0c67a5",
                    disabled: _vm.selected.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      ;(_vm.component = "PostBatch"),
                        (_vm.selectedBatches = _vm.selected),
                        (_vm.modal = true)
                    },
                  },
                },
                [
                  _vm._v(" Post Selected Batch "),
                  _c("v-icon", { attrs: { color: "#fff", right: "" } }, [
                    _vm._v("mdi-arrow-up-bold-box"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2 mb-7",
                  attrs: { height: "40", color: "success" },
                  on: {
                    click: function ($event) {
                      ;(_vm.component = "BatchModal"), (_vm.modal = true)
                    },
                  },
                },
                [_vm._v(" New Batch ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        directives: [
          {
            name: "ripple",
            rawName: "v-ripple",
            value: false,
            expression: "false",
          },
        ],
        staticClass: "table",
        style: { position: "relative", "z-index": "1" },
        attrs: {
          dense: "",
          "items-per-page": _vm.pageSize,
          headers: _vm.headers,
          height: _vm.tableSize,
          items: _vm.selectedItems,
          loading: _vm.loading,
          "item-key": "id",
          search: _vm.filter,
          "show-select": "",
          "single-select": "",
          "fixed-header": "",
          "hide-default-footer": "",
        },
        on: {
          "update:items": function ($event) {
            _vm.selectedItems = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header.info",
              fn: function (ref) {
                var header = ref.header
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(_vm._s(header.text)),
                  ]),
                ]
              },
            },
            {
              key: "header.general_col_divider",
              fn: function (ref) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "white--text",
                                      attrs: { icon: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.column1 = !_vm.column1
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    !_vm.column1
                                      ? _c("v-icon", [
                                          _vm._v(" mdi-chevron-left "),
                                        ])
                                      : _vm._e(),
                                    _vm.column1
                                      ? _c("v-icon", [
                                          _vm._v(" mdi-chevron-right "),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.column1 ? "Collapse" : "Expand")),
                      ]),
                    ]
                  ),
                ]
              },
            },
            {
              key: "item.data-table-select",
              fn: function (ref) {
                var item = ref.item
                var isSelected = ref.isSelected
                var select = ref.select
                return [
                  item.status === "COMPLETE"
                    ? _c("v-simple-checkbox", {
                        attrs: { value: isSelected },
                        on: { input: select },
                      })
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.status",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        small: "",
                        dark: "",
                        color: _vm.getColor(item.status),
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            margin: "0 auto",
                            width: "95px",
                            "text-align": "center",
                            "font-size": "11px",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(item.status.replace(/_/g, " ")) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: "item.start_date",
              fn: function (ref) {
                var item = ref.item
                return [
                  item && item.start_date
                    ? _c("span", { staticClass: "noWrap" }, [
                        _vm._v(
                          " " + _vm._s(_vm.formatDate(item.start_date)) + " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.end_date",
              fn: function (ref) {
                var item = ref.item
                return [
                  item && item.end_date
                    ? _c("span", { staticClass: "noWrap" }, [
                        _vm._v(
                          " " + _vm._s(_vm.formatDate(item.end_date)) + " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.post_date",
              fn: function (ref) {
                var item = ref.item
                return [
                  item && item.post_date
                    ? _c("span", { staticClass: "noWrap" }, [
                        _vm._v(
                          " " + _vm._s(_vm.formatDate(item.post_date)) + " "
                        ),
                      ])
                    : _c("span", [_vm._v("---")]),
                ]
              },
            },
            {
              key: "item.post_id",
              fn: function (ref) {
                var item = ref.item
                return [
                  item.post_id && _vm.column1
                    ? _c(
                        "span",
                        {
                          staticClass: "noWrap",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.copyValue(item.post_id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.post_id.substring(0, 8).toUpperCase()
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c("span", [_vm._v("---")]),
                ]
              },
            },
            {
              key: "item.gen_col_divider",
              fn: function (ref) {
                return [_c("v-divider", { attrs: { vertical: "" } })]
              },
            },
            {
              key: "item.last_update_date",
              fn: function (ref) {
                var item = ref.item
                return [
                  item && item.last_update_date && _vm.column1
                    ? _c("span", { staticClass: "noWrap" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDateTime(item.last_update_date)) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.info",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.copyValue(item.id)
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-information")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(item.id))])]
                  ),
                ]
              },
            },
            {
              key: "item.user_id",
              fn: function (ref) {
                var item = ref.item
                return [
                  _vm.column1
                    ? _c("div", { staticClass: "truncate" }, [
                        _vm._v(_vm._s(item.user_id)),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.actions",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "open-on-click": "",
                        transition: "slide-x-transition",
                        bottom: "",
                        right: "",
                        "offset-y": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { icon: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-auto",
                          attrs: { "max-width": "300", tile: "" },
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            [
                              _c("v-subheader", [_vm._v("Actions")]),
                              _c(
                                "v-list-item-group",
                                { attrs: { color: "primary" } },
                                [
                                  _vm._l(_vm.actions, function (action, i) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: i,
                                        attrs: {
                                          disabled: _vm.isDetails(
                                            action.description,
                                            item.status
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            ;(_vm.selectedBatch = item),
                                              (_vm.component =
                                                action.component),
                                              (_vm.modal = true)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  disabled: _vm.isDetails(
                                                    action.description,
                                                    item.status
                                                  ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(action.icon) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(action.description) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  _vm.isCancellable(item)
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              ;(_vm.selectedBatch = item),
                                                (_vm.component =
                                                  "ConfirmCancel"),
                                                (_vm.modal = true)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-icon", [
                                                _vm._v(" mdi-cancel "),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v("Cancel Batch"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        disabled:
                                          item.is_processing ||
                                          item.status == "POSTED",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          ;(_vm.selectedBatch = item),
                                            (_vm.component = "ConfirmRerun"),
                                            (_vm.modal = true)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                disabled:
                                                  item.is_processing ||
                                                  item.status == "POSTED",
                                              },
                                            },
                                            [_vm._v(" mdi-run-fast ")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Re-run Batch"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _vm.postProcesses
                                ? _c(
                                    "v-list-item-group",
                                    [
                                      ["SCAN", "REBILL"].includes(
                                        item.batch_type
                                      )
                                        ? _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                disabled:
                                                  (item.status !== "COMPLETE" &&
                                                    item.status !== "POSTED") ||
                                                  item.is_processing,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sendProcess(
                                                    item,
                                                    _vm.postProcesses
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          item.status !==
                                                            "COMPLETE" &&
                                                          item.status !==
                                                            "POSTED",
                                                      },
                                                    },
                                                    [_vm._v(" mdi-blender ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.postProcesses
                                                            .kv_name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _c("v-divider"),
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "toolbar" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "end" } },
            [
              _c(
                "v-col",
                { staticStyle: { "max-width": "150px" }, attrs: { cols: "2" } },
                [
                  _c("v-select", {
                    staticClass: "mt-8",
                    attrs: {
                      dense: "",
                      items: _vm.pageSizes,
                      label: "Items Per Page",
                      select: "",
                      "menu-props": {
                        top: true,
                        offsetY: true,
                        maxHeight: 500,
                      },
                    },
                    model: {
                      value: _vm.pageSize,
                      callback: function ($$v) {
                        _vm.pageSize = $$v
                      },
                      expression: "pageSize",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { icon: "", large: "", disabled: _vm.disablePrevious },
                  on: {
                    click: function ($event) {
                      return _vm.previous()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { icon: "", large: "", disabled: _vm.disableNext },
                  on: {
                    click: function ($event) {
                      return _vm.next()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(_vm.component, {
            tag: "Component",
            attrs: {
              id: _vm.id,
              value: _vm.modal,
              selectedBatch: _vm.selectedBatch,
              selectedBatches: _vm.selectedBatches,
              selected: _vm.selected,
            },
            on: {
              "update:value": function ($event) {
                _vm.modal = $event
              },
              close: _vm.closeModal,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }